import React, { useState, useEffect } from 'react'
import LocalStorage from 'utils/LocalStorage'
import HasAccess from 'core/HasAccess'
import VERSION from 'VERSION'
import { connect, useDispatch, useSelector } from 'react-redux'
import { updateCharts, updateStoragePath } from 'core/charts'
import ChartRenderer from '../components/ChartRenderer'
import Dashboard from '../components/Dashboard'
import DashboardItem from '../components/DashboardItem'
import charts from './charts'

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 4,
  minH: 8,
})

const connector = connect(({ user: { permissions } }) => ({ permissions }), {})

const CommonPage = ({
  name, currentLenderId, permissions,
}) => {
  const defaultData = charts[name]({ currentLenderId })
  const storagePath = ['lenderPortal', 'charts', name, currentLenderId, VERSION]
  const dataFromLocalStorage = LocalStorage.getIn(storagePath) || defaultData
  const dispatch = useDispatch()
  const data = useSelector(({ charts: { data } }) => data)

  const setData = (data) => {
    dispatch(updateCharts(data, storagePath))
  }

  const [isDraggable, setIsDraggable] = useState(true)

  useEffect(() => {
    if (!LocalStorage.getIn(storagePath)) {
      setData(charts[name]({ currentLenderId }))
    } else {
      setData(LocalStorage.getIn(storagePath) || defaultData)
      // setData(defaultData)
    }
  }, [currentLenderId])

  useEffect(() => {
    dispatch(updateStoragePath(storagePath))
  }, [currentLenderId, name])

  const filteredData = data.filter((i) => !i.newFeature || HasAccess.run(permissions, 'lpNewFeature'))

  return (
    <div>
      <Dashboard dashboardItems={dataFromLocalStorage} setData={setData} isDraggable={isDraggable}>
        {filteredData.map((item) => (
          <div data-grid={defaultLayout(item)} key={item.id}>
            <DashboardItem
              data={data}
              item={item}
              itemId={item.id}
              key={item.id}
              setData={setData}
              setIsDraggable={setIsDraggable}
            >
              <ChartRenderer vizState={{ ...item.vizState, id: item.id }} id={item.id} />
            </DashboardItem>
          </div>
        ))}
      </Dashboard>
    </div>
  )
}

export default connector(CommonPage)
