import { Form, Select } from 'antd'
import React from 'react'
import { getIn, setIn } from 'utils/immutable'
import Utils from 'utils'
import lenderGroups from 'lenderGroups'

const { Option } = Select

// deprecated
const LenderGroups = ({ item, updateItem, option }) => {
  const getDefaultValue = () => getIn(item, ['vizState', 'lenderGroup'])
  const type = Utils.Cube.getType(item)

  const handleChange = (value) => {
    const newItem = setIn(item, ['vizState', 'lenderGroup'], value)
    updateItem(item.id, newItem)
  }
  const getOptions = () => {
    const groups = lenderGroups[type].map((g) => ({ label: g.name, value: g.name }))
    if (option.exclude) {
      return groups.filter((group) => !option.exclude.includes(group.value))
    }

    return groups
  }

  return (
    <Form.Item label="Lender Group">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select chart type"
      >
        {getOptions().map((opt) => <Option key={opt.value} value={opt.value}>{opt.label}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default LenderGroups
