import React from 'react'
import { updateFilters as updateFiltersAction } from 'core/filters'
import { v4 as uuidv4 } from 'uuid'
import HasAccess from 'core/HasAccess'
import dimensions from 'dimensions'
import Utils from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import CustomFilter from './CustomFilter'

const getDefaultFilter = () => ({
  dimension: 'region',
  operator: 'equals',
  values: [],
  id: uuidv4(),
})

const CustomFilters = ({ item, type }) => {
  const dispatch = useDispatch()
  const permissions = useSelector(({ user: { permissions } }) => permissions)
  const filtersRedux = useSelector(({ filters }) => filters[type])

  const unifiedFilters = filtersRedux

  const getFilterDimensions = () => {
    const ttype = type || Utils.Cube.getType(item)
    return dimensions.filter((dim) => !dim.intendedFor || dim.intendedFor === ttype)
  }

  const updateFilters = (newFilters) => {
    dispatch(updateFiltersAction(newFilters, type))
  }

  const handleAdd = () => {
    if (!HasAccess.run(permissions, 'lpMultiFilters')
     && !HasAccess.run(permissions, 'lpTwoFilters')
      && !HasAccess.run(permissions, 'lpThreeFilters')) return
    if (HasAccess.run(permissions, 'lpTwoFilters') && unifiedFilters.length > 2) return
    if (HasAccess.run(permissions, 'lpThreeFilters') && unifiedFilters.length > 3) return

    updateFilters([...unifiedFilters, getDefaultFilter()])
  }

  const handleRemove = (filter) => {
    const newFilters = unifiedFilters.filter((f) => f.id !== filter.id)
    updateFilters(newFilters)
  }
  const handleUpdate = (filter, attrs) => {
    const newFilters = unifiedFilters.map((f) => (f.id === filter.id ? { ...filter, ...attrs } : f))
    updateFilters(newFilters)
  }

  const ttype = type || Utils.Cube.getType(item)

  return (
    <div>
      <div>Custom Filters</div>
      {unifiedFilters.map((filter, index) => (
        <CustomFilter
          key={index}
          index={index}
          size={unifiedFilters.length}
          filter={filter}
          type={ttype}
          onAdd={handleAdd}
          onRemove={handleRemove}
          onUpdate={handleUpdate}
          dimensions={getFilterDimensions()}
        />
      ))}
    </div>
  )
}

export default CustomFilters
