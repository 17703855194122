import Utils from 'utils'
import LocalStorage from 'utils/LocalStorage'
import VERSION from 'VERSION'

const DEFAULT_GROUPING = 'month'
const DEFAULT_RERUNS = 'with'

export const initialState = LocalStorage.getIn(['globalOptions', VERSION]) || {
  groupingByTime: DEFAULT_GROUPING,
  filterByTime: null,
  reRuns: DEFAULT_RERUNS,
}

export const UPDATE_GLOBAL_OPTIONS = 'filters/UPDATE_GLOBAL_OPTIONS'

export const updateGlobalOptions = (name, value) => ({
  type: UPDATE_GLOBAL_OPTIONS,
  payload: { name, value },
})

const HANDLERS = {
  [UPDATE_GLOBAL_OPTIONS]: (state, { payload: { name, value } }) => {
    const newState = { ...state, [name]: value }
    LocalStorage.setIn(['globalOptions', VERSION], newState)
    return newState
  },
}

export default Utils.Redux.combineHandlers(HANDLERS, initialState)
