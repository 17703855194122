// TODO (atanych): I totally agree, that storing something in window object is not good idea.
// But, unfortunately it's tricky to avoid this way.
// When we have time, we have to revise this helper
const Report = {
  PREFIX: 'tableDataChart',

  setData: (id, data, columns) => {
    const headers = columns.map((c) => c.title)
    const body = data.map((el) => columns.map((c) => el[c.key]))
    window[`${Report.PREFIX}${id}`] = [headers, ...body]
  },

  getData: (id) => window[`${Report.PREFIX}${id}`],
}

export default Report
