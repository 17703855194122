import React from 'react'
import {
  Row, Col, Modal, Button, Form,
} from 'antd'
import { connect } from 'react-redux'
import options from './options'

const connector = connect(({ user: { permissions } }) => ({ permissions }), {})

const EditModal = ({
  setModal, title, modal, setData, data, item, permissions,
}) => {
  const updateItem = (itemId, attrs) => {
    setData(data.map((item) => (item.id === itemId ? { ...item, ...attrs } : item)))
  }

  return (
    <Modal
      footer={
        [
          <Button key="submit" onClick={() => setModal(false)} type="primary">
            Close
          </Button>,
        ]
      }
      onCancel={() => setModal(false)}
      title={`Edit ${title}`}
      visible={modal}
    >
      <Row align="top" justify="space-around" key="1" type="flex">
        <Col span={24}>
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            {(item.options || []).map((option, index) => {
              const OptionComponent = options[option.name]
              if (!OptionComponent) {
                // eslint-disable-next-line no-console
                console.error('OptionComponent is not implemented for', option.name)
                return null
              }
              return (
                <OptionComponent
                  key={index}
                  option={option}
                  item={item}
                  permissions={permissions}
                  updateItem={updateItem}
                />
              )
            })}
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}
export default connector(EditModal)
