export default () => [

  {
    id: '100001',
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'mbtCasesBar', label: 'Bar' }, { value: 'mbtCasesTable', label: 'Table' }],
      },
      { name: 'measure' },
      { name: 'dimension' },

      { name: 'data_format', options: ['absolute', 'percentage'] },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [
        ],
        filters: [
          'Cases.COMPANIES',
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      dataFormat: 'absolute',
      chartType: 'mbtCasesBar',
      sessionGranularity: 'month',
    },
    name: 'Your Cases - BTL',
    description: 'Overview of the composition and volume of the cases you run through MBT. Ability to filter by various dimensions',
    __typename: 'DashboardItem',
  },

  {
    id: '100002',
    layout: {
      x: 12,
      y: 0,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'line', label: 'Line' }, { value: 'bar', label: 'Bar' }, { value: 'table', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.affordablePercentage',
          'Cases.unaffordablePercentage',
          'Cases.unlendablePercentage',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-01-01',
            ],
          },
          'Cases.COMPANIES',
        ],
      },
      chartType: 'bar',
      yAxis: {
        formatter: '%',
        ticks: [0, 25, 50, 75, 100],
      },
      sessionGranularity: 'month',
    },
    name: 'Your Cases Affordability - BTL',
    description: 'Understand what % of your cases are affordable (meet the loan requested), unaffordable (do not meed the loan requested) or will not lend (not offered a loan)',
    __typename: 'DashboardItem',
  },

  {
    id: '100003',
    layout: {
      x: 0,
      y: 7,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'topXBarChart', label: 'Bar' }, { value: 'topXTable', label: 'Table' },
        ],
      },

      {
        name: 'top_position',
        options: [
          { value: 'Top1Lenders', label: 'TOP-1' },
          { value: 'TopAmong5Lenders', label: 'Among-5' },
          { value: 'TopAmong10Lenders', label: 'Among-10' },
        ],
      },

    ],
    vizState: {
      custom: 'afford_ranking',
      disabledLegend: true,
      disabledFilters: [3],
      maxPositions: 10,
      query: [{
        measures: [
          'Top1Lenders.count',
        ],
        timeDimensions: [
          {
            dimension: 'Top1Lenders.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'Top1Lenders.lenderName',
        ],
        filters: [
          {
            dimension: 'Top1Lenders.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Top1Lenders.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
          'Top1Lenders.COMPANIES',
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
          'CaseResults.COMPANIES',
        ],
      }, {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
          'Cases.COMPANIES',
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
          'CaseResults.COMPANIES',
        ],
      }],
      chartType: 'topXBarChart',
      yAxis: {
        formatter: '%',
      },
      sessionGranularity: 'month',
    },
    name: 'Top ranked lenders for your cases - BTL',
    description: 'See which lenders are most competitive on affordability for your cases (top 10 shown)',
    __typename: 'DashboardItem',
  },

  {
    id: '100004',
    layout: {
      x: 12,
      y: 7,
      w: 12,
      h: 8,
    },
    options: [

    ],
    vizState: {

      disabledLegend: true,
      query: {
        measures: [
          'CasesWithUsers.count',
        ],

        timeDimensions: [
          {
            dimension: 'CasesWithUsers.updatedAt',
          },
        ],
        order: {},
        dimensions: [
          'CasesWithUsers.name',
        ],
        filters: [
          'CasesWithUsers.COMPANIES',
          {
            dimension: 'CasesWithUsers.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CasesWithUsers.updatedAt',
            operator: 'afterDate',
            values: [
              '2020-07-01',
            ],
          },
        ],
      },
      chartType: 'excludedReasonsTable',
      limit: 9999999,
    },
    ignoredGlobalOptions: ['groupingByTime'],
    name: 'Top MBT Users - BTL',
    description: 'See who uses MBT the most, and can be internal advocates',
    __typename: 'DashboardItem',
  },
  {
    id: '100005',
    newFeature: true,
    layout: {
      x: 0,
      y: 13,
      w: 12,
      h: 8,
    },
    options: [

    ],
    vizState: {
      disabledLegend: true,
      chartType: 'usersActivitiesTable',
    },
    ignoredGlobalOptions: ['groupingByTime', 'filterByTime', 'reRuns'],
    ignoreGlobalFilters: true,
    name: 'MBT Users',
    description: 'Snapshot of the number of registered MBT users, and those that are "active" (if they have ran a case through MBT)',
    __typename: 'DashboardItem',
  },
]
