import React from 'react'
import _ from 'lodash'
import { Table } from 'antd'
import Utils from 'utils'

const DEFAULT_LIMIT = 10

const getColumns = (vizState) => {
  const schema = Utils.Cube.getSchema(vizState)
  return [
    {
      dataIndex: `${schema}.name`,
      align: 'left',
      key: `${schema}.name`,
      title: 'Name',
    },
    {
      dataIndex: `${schema}.count`,
      align: 'center',
      key: `${schema}.count`,
      title: 'Count',
    },
  ]
}

const normalizeData = (loadResponses, vizState) => {
  const schema = Utils.Cube.getSchema(vizState)
  const data = _.sortBy(loadResponses[0].data, (item) => -parseInt(item[`${schema}.count`], 10))
  return _.take(data, vizState.limit || DEFAULT_LIMIT)
}

const ExcludedReasonsTable = ({ resultSet, vizState, height }) => {
  const data = normalizeData(resultSet.loadResponses, vizState)
  const columns = getColumns(vizState)

  Utils.Report.setData(vizState.id, data, columns)

  const schema = Utils.Cube.getSchema(vizState)
  return (
    <>
      <div style={{ maxHeight: `${height - 4}px`, overflowY: 'auto' }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey={(item) => item[`${schema}.name`]}
        />
      </div>
    </>
  )
}

export default ExcludedReasonsTable
