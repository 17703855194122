import Utils from 'utils'
import _ from 'lodash'
import LocalStorage from 'utils/LocalStorage'
import UpdateItem from 'components/DashboardItem/options/CustomFilters/UpdateItem'
import { setIn, deleteIn } from 'utils/immutable'
import { UPDATE_FILTERS } from './filters'
import { UPDATE_GLOBAL_OPTIONS } from './globalOptions'

export const initialState = {
  data: [],
  storagePath: null,
}

const WITHOUT_RERUNS_SUF = 'WithoutReruns'
export const UPDATE_CHARTS = 'charts/UPDATE_CHARTS'
export const UPDATE_STORAGE_PATH = 'charts/UPDATE_STORAGE_PATH'

export const updateCharts = (data, storagePath) => ({
  type: UPDATE_CHARTS,
  payload: { data, storagePath },
})

export const updateStoragePath = (storagePath) => ({
  type: UPDATE_STORAGE_PATH,
  payload: { storagePath },
})

const HANDLERS = {
  [UPDATE_CHARTS]: (state, { payload: { data, storagePath } }) => {
    LocalStorage.setIn(storagePath || state.storagePath, data)
    return { ...state, data, storagePath }
  },
  [UPDATE_STORAGE_PATH]: (state, { payload: { storagePath } }) => ({ ...state, storagePath }),
  [UPDATE_FILTERS]: (state, { payload: { filters } }) => {
    const data = state.data.map((item) => {
      if (item.ignoreGlobalFilters) return item

      return UpdateItem.run(item, filters)
    })
    LocalStorage.setIn(state.storagePath, data)
    return { ...state, data }
  },
  [UPDATE_GLOBAL_OPTIONS]: (state, { payload: { name, value } }) => {
    let alterQuery
    if (name === 'filterByTime') {
      alterQuery = (query, value) => {
        if (value === '') return deleteIn(query, ['timeDimensions', 0, 'dateRange'])
        return setIn(query, ['timeDimensions', 0, 'dateRange'], value)
      }
    }
    if (name === 'groupingByTime') {
      alterQuery = (query, value) => setIn(query, ['timeDimensions', 0, 'granularity'], value)
    }
    if (name === 'reRuns') {
      alterQuery = (query, value) => {
        const schema = query.measures[0].split('.')[0]
        const newSchema = value === 'without' ? `${schema}${WITHOUT_RERUNS_SUF}` : schema.split(WITHOUT_RERUNS_SUF)[0]

        const measures = query.measures.map((measure) => {
          const tail = measure.split('.')[1]
          return `${newSchema}.${tail}`
        })

        const timeDimensions = query.timeDimensions.map((dimension) => {
          const tail = dimension.dimension.split('.')[1]
          return { ...dimension, dimension: `${newSchema}.${tail}` }
        })

        const dimensions = (query.dimensions || []).map((dimension) => {
          const tail = dimension.split('.')[1]
          return `${newSchema}.${tail}`
        })

        const segments = (query.segments || []).map((segment) => {
          const tail = segment.split('.')[1]
          return `${newSchema}.${tail}`
        })

        const filters = query.filters.map((filter) => {
          if (_.isString(filter)) {
            const [, ...tail] = filter.split('.')
            return `${newSchema}.${tail.join('.')}`
          }

          const dimensionTail = filter.dimension.split('.')[1]
          return { ...filter, dimension: `${newSchema}.${dimensionTail}` }
        })

        return {
          ...query, measures, dimensions, timeDimensions, segments, filters,
        }
      }
    }
    // TODO: consider saga for side effects
    const data = state.data.map((item) => {
      if (item.ignoredGlobalOptions && item.ignoredGlobalOptions.includes(name)) return item

      return Utils.Cube.updateQuery(item, alterQuery, value)
    })
    LocalStorage.setIn(state.storagePath, data)
    return { ...state, data }
  },
}

export default Utils.Redux.combineHandlers(HANDLERS, initialState)
