import React from 'react'
import _ from 'lodash'
import { removeLastElement } from 'utils/array'
import Utils from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { Table as AntTable } from 'antd'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Legend,
  Bar as AntBar,
  ResponsiveContainer,
} from 'recharts'
import { xAxisFormatter } from '../CartesianChart'
import colors from '../colors'

const DEVIATIONS_FRAMES = [
  '< 5%', '5-10%', '10-15%', '15-20%', '20-25%', '25-30%', '30-40%', '40-50%', '50-60%', '60-80%', '> 80%',
]

const MyDeviation = ({ resultSet, height, vizState }) => {
  const buildData = () => Utils.Cube.percentageData(resultSet.chartPivot(), vizState)

  const tooltipFormat = (v, t) => {
    const title = t.split(',')[0]
    if (Utils.Cube.isPercentage(vizState)) return [`${Math.round(v) || 0}%`, title]

    return [Math.round(v), title]
  }

  const getSeriesNames = () => {
    const seriesNames = _.keyBy(resultSet.seriesNames(), (series) => removeLastElement(series.yValues))
    return DEVIATIONS_FRAMES.map((order) => seriesNames[order]).filter((order) => order)
  }

  const formatLegend = (value) => removeLastElement(value.split(',')).join(',')

  const data = buildData()

  if (vizState.chartType === 'myDeviationTable') {
    return <Table data={data} vizState={vizState} />
  }

  return (
    <ResponsiveContainer height={height} key={vizState.dataFormat} width="100%">
      <BarChart data={data} margin={{ left: -10 }}>
        <XAxis axisLine={false} dataKey="x" minTickGap={20} tickFormatter={(v) => xAxisFormatter(v, vizState)} />
        <YAxis axisLine={false} tickLine unit="%" ticks={[0, 25, 50, 75, 100]} />
        <CartesianGrid vertical={false} />
        {getSeriesNames().map((series, i) => (
          <AntBar
            dataKey={series.key}
            fill={colors[i % (colors.length - 1)]}
            key={series.key}
            name={series.title}
            stackId="a"
          />
        ))}
        <Legend formatter={formatLegend} />
        <Tooltip
          formatter={tooltipFormat}
          labelFormatter={(v) => xAxisFormatter(v, vizState, 'full')}
          wrapperStyle={{ zIndex: 2 }}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

const Table = ({ data, vizState }) => {
  const schema = Utils.Cube.getSchema(vizState)
  const dimensions = Utils.Cube.getDimensions(vizState)

  const getColumns = () => [
    { dataIndex: 'date', key: 'date', title: 'Date' },
    { key: `${schema}.deviationFrames`, dataIndex: `${schema}.deviationFrames`, title: 'Deviation' },
    { dataIndex: 'count', key: 'count', title: 'Count' },
  ]

  const sort = (a, b, { key }) => {
    if (key.includes('deviationFrames')) {
      return _.indexOf(DEVIATIONS_FRAMES, a[key]) - _.indexOf(DEVIATIONS_FRAMES, b[key])
    }
    const dimension = _.last(key.split('.'))
    if (dimension === 'count') return a[key] - b[key]

    return a[key].localeCompare(b[key])
  }

  const alteredData = _.reduce(data, (result, item) => {
    const arr = _.reduce(item, (res, value, key) => {
      if (!key.includes(schema)) return res
      const keys = key.split(',')
      const row = dimensions.reduce((res, dim, i) => ({ ...res, [dim]: keys[i] }), {})
      return [...res, {
        ...row, date: item.category.split('T')[0], count: _.round(value, 2), key: uuidv4(),
      }]
    }, [])
    return [...result, ...arr]
  }, [])

  Utils.Report.setData(vizState.id, alteredData, getColumns())

  return (
    <AntTable
      columns={getColumns().map((c) => ({ ...c, sorter: (a, b) => sort(a, b, c) }))}
      dataSource={alteredData}
      pagination={false}
      style={{ height: '300px', overflowY: 'auto' }}
    />
  )
}

export default MyDeviation
