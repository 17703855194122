import { combineReducers } from 'redux'
import user from './user'
import filters from './filters'
import charts from './charts'
import globalOptions from './globalOptions'
import currentLenderId from './currentLenderId'

const reducer = combineReducers({
  user,
  currentLenderId,
  filters,
  charts,
  globalOptions,
})

export default reducer
