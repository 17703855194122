import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { Layout } from 'antd'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { ApolloProvider } from 'react-apollo'
import cubejs from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import { Hub } from 'aws-amplify'
import HasAccess from 'core/HasAccess'
import { setUser } from 'core/user'
import { connect } from 'react-redux'
import client from './graphql/client'
import Header from './components/Header'
import CommonPage from './pages/CommonPage'

const connector = connect(
  ({ user, currentLenderId }) => ({ user, currentLenderId }),
  { setUser },
)
const cubejsApi = cubejs('SECRET', {
  credentials: 'include',
  method: 'POST',
  apiUrl: `${process.env.REACT_APP_SERVER_DOMAIN}/cubejs-api/v1`,
})

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signOut') {
    client.resetStore()
  }
})

const AppLayout = withRouter(({ location, children }) => (
  <Layout style={{ height: '100%' }}>
    <Header location={location} />
    <Layout.Content style={{ marginTop: '60px' }}>{children}</Layout.Content>
  </Layout>
))

const App = ({ setUser, user, currentLenderId }) => {
  useEffect(() => { setUser() }, [])

  return (
    <Router>
      <CubeProvider cubejsApi={cubejsApi}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <AppLayout location={location}>
              {!!user.lenders.length && HasAccess.run(user.permissions, 'lpCompetitiveResi') && (
                <Route
                  render={() => (
                    <CommonPage
                      name="resiCommon"
                      currentLenderId={currentLenderId}
                    />
                  )}
                  exact
                  key="resi-common"
                  path="/"
                />
              )}
              {!!user.lenders.length && HasAccess.run(user.permissions, 'lpCompetitiveBtl') && (
                <Route
                  render={() => (
                    <CommonPage
                      name="btlCommon"
                      currentLenderId={currentLenderId}
                    />
                  )}
                  exact
                  key="btl-common"
                  path="/btl-common"
                />
              )}
              {HasAccess.run(user.permissions, 'lpBrokerInsights') && (
                <Route
                  render={() => (
                    <CommonPage
                      name="resiBroker"
                      currentLenderId={currentLenderId}
                    />
                  )}
                  exact
                  key="resiBroker"
                  path="/broker-insights-resi"
                />
              )}
              {HasAccess.run(user.permissions, 'lpBrokerInsights') && (
                <Route
                  render={() => (
                    <CommonPage
                      name="btlBroker"
                      currentLenderId={currentLenderId}
                    />
                  )}
                  exact
                  key="btlBroker"
                  path="/broker-insights-btl"
                />
              )}
            </AppLayout>
          </ApolloHooksProvider>
        </ApolloProvider>
      </CubeProvider>
    </Router>
  )
}

export default connector(App)
