import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import Utils from 'utils'
import { v4 as uuidv4 } from 'uuid'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
} from 'recharts'
import { Table as AntTable } from 'antd'
import { connect } from 'react-redux'
import { xAxisFormatter, yAxisFormatter } from '../CartesianChart'
import colors from '../colors'

const connector = connect(({ user, currentLenderId }) => ({ user, currentLenderId }), {})

const MAX_POSITIONS = 5
const OTHERS_COLOR = 'grey'
const UNAFFORDABLE_COLOR = '#f74141'
const MY_LENDER_COLOR = '#1c8a93'
const UNAFFORDABLE = 'Unaffordable'
const OTHERS = 'Others'

const isAmong = (vizState) => {
  const chart = vizState.query[0].measures[0].split('.')[0]
  return chart.includes('Among')
}

const TopXBarChart = ({
  resultSet, height, vizState, user, currentLenderId,
}) => {
  const buildResult = (loadResponses) => {
    const topXSchema = getSchemaByIndex(0)
    const resultMap = _.groupBy(loadResponses[0].data, `${topXSchema}.updatedAt`)
    if (resultMap.undefined) return []
    return _.map(resultMap, (lenders, period) => {
      lenders = selectProperLenders(lenders, vizState)
      // if (period === '2020-09-01T00:00:00.000') {
      //   console.log('period', period, lenders)
      // }
      lenders = convertToPercents(lenders, loadResponses)
      lenders = filterByCriteria(lenders, vizState)
      // additional conversion to achieve sum of results equal 100%. Is not intended for amongX
      if (!isAmong(vizState)) {
        // we should not fix deviation for BP, because for BP are used total cases values (total of all cases)
        if (!Utils.Cube.isFilteredByCompany(vizState)) {
          const total = _.sumBy(lenders, (lender) => lender.count)
          lenders = lenders.map((lender) => (
            { ...lender, count: (lender.count * 100) / total }
          ))
        }
        lenders = sortLenders(lenders)
      }
      return formatToCube(lenders, period, vizState)
    })
  }

  const buildSeries = (result) => {
    const resultMap = result.reduce((res, period) => ({ ...res, ...period }), {})
    const seriesNames = _.reduce(resultMap, (res, _name, key) => {
      if (['category', 'x', 'xValues'].includes(key)) return res
      return [...res, { key, title: key, yValues: [key] }]
    }, [])
    return _.sortBy(seriesNames, (series) => {
      if (series.key === UNAFFORDABLE) return 10
      if (series.key === OTHERS) return 9
      return 8
    })
  }

  const selectProperLenders = (lenders, vizState) => {
    if (!isAmong(vizState)) return lenders

    const topXSchema = getSchemaByIndex(0)

    if (vizState.lenders && vizState.lenders.length) {
      const selectedLenderNames = vizState.lenders.map((l) => l[1])
      return lenders.filter((lender) => selectedLenderNames.includes(lender[`${topXSchema}.lenderName`]))
    }

    return lenders.filter((lender) => lender[`${topXSchema}.lenderName`] === lenderName)
  }

  const formatToCube = (lenders, period, vizState) => {
    const baseCubeObject = { category: period, x: period, xValues: [period] }

    if (vizState.lenders) {
      const avg = _.meanBy(lenders, (lender) => (lender.baseCount * 100) / lender.total) || 0
      return { ...baseCubeObject, Custom: avg }
    }

    return lenders.reduce((r, lender) => ({
      ...r,
      [lender.name]: isAmong(vizState) ? (lender.baseCount * 100) / lender.total : lender.count,
    }), baseCubeObject)
  }

  const getSchemaByIndex = (index) => vizState.query[index].measures[0].split('.')[0]

  const getMaxPositions = () => vizState.maxPositions || MAX_POSITIONS

  const sortLenders = (lenders) => {
    if (!lenders.length) return lenders
    if (isAmong(vizState)) return lenders

    lenders = _.sortBy(lenders, (lender) => -parseFloat(lender.count))

    // eslint-disable-next-line prefer-const
    let [updatedLenders, unaffordable, myLender] = _.reduce(lenders, ([all, unaffordable, myLender], lender) => {
      if (lender.name === UNAFFORDABLE) return [all, lender, myLender]
      if (lender.name === lenderName) return [[...all, lender], unaffordable, lender]

      return [[...all, lender], unaffordable, myLender]
    }, [[], null, null])

    const others = updatedLenders.reduce((others, lender, index) => {
      if (index < getMaxPositions()) return others
      if (lender.name === lenderName) return others
      return { ...others, count: lender.count + others.count }
    }, {
      name: OTHERS,
      updatedAt: lenders[0].updatedAt,
      count: 0,
    })
    updatedLenders = _.take(updatedLenders, getMaxPositions())

    updatedLenders = [...updatedLenders, others]
    updatedLenders = unaffordable ? [...updatedLenders, unaffordable] : updatedLenders
    updatedLenders = myLender && !_.some(
      updatedLenders, (v) => v.name === lenderName,
    ) ? [...updatedLenders, myLender] : updatedLenders
    return updatedLenders
  }

  const convertToPercents = (lenders, loadResponses) => {
    const topXSchema = getSchemaByIndex(0)
    const caseResultsSchema = getSchemaByIndex(1)
    const casesSchema = getSchemaByIndex(2)
    const caseResultsWithoutFiltersSchema = getSchemaByIndex(3)
    if (!lenders.length) return lenders

    const period = lenders[0][`${topXSchema}.updatedAt`]
    const lenderMap = _.keyBy(
      loadResponses[1].data, (a) => (
        [a[`${caseResultsSchema}.lenderName`], a[`${caseResultsSchema}.updatedAt`]]
      ),
    )
    const lenderWithoutFiltersMap = _.keyBy(
      loadResponses[3].data, (a) => (
        [a[`${caseResultsWithoutFiltersSchema}.lenderName`], a[`${caseResultsWithoutFiltersSchema}.updatedAt`]]
      ),
    )

    const caseMap = _.keyBy(loadResponses[2].data, `${casesSchema}.updatedAt`)
    return lenders.map((lender) => {
      const lenderCases = lenderMap[[lender[`${topXSchema}.lenderName`], period]] || {}
      const lenderCasesWithoutFilters = lenderWithoutFiltersMap[[lender[`${topXSchema}.lenderName`], period]] || {}
      const lenderCasesCount = parseInt(lenderCases[`${caseResultsSchema}.count`], 10) || 0
      const totalCases = parseInt(caseMap[period][`${casesSchema}.count`], 10)
      const totalForLender = Utils.Cube.isFilteredByCompany(vizState) ? totalCases : lenderCasesCount
      const count = parseFloat(lender[`${topXSchema}.count`])
      if (!lender[`${topXSchema}.lenderName`]) {
        return {
          ...lender,
          name: UNAFFORDABLE,
          count: (count * 100) / totalCases,
          total: totalCases,
        }
      }
      return {
        ...lender,
        baseCount: count,
        name: lender[`${topXSchema}.lenderName`],
        count: (count * 100) / totalForLender,
        total: totalForLender,
        totalWithoutFilters: parseInt(lenderCasesWithoutFilters[`${caseResultsWithoutFiltersSchema}.count`], 10),
      }
    })
  }

  const THRESHOLDS = {
    residential: {
      week: 200,
      month: 1000,
      year: 1000,
    },
    buy_to_let: {
      week: 20,
      month: 100,
      year: 100,
    },
  }

  const filterByCriteria = (lenders, vizState) => {
    // we dont use thresholds for company specific sampling
    if (Utils.Cube.isFilteredByCompany(vizState)) return lenders

    const type = Utils.Cube.getType({ vizState })
    const threshold = THRESHOLDS[type][vizState.query[0].timeDimensions[0].granularity]
    const result = lenders.filter(
      (lender) => !_.hasIn(lender, 'totalWithoutFilters') || lender.totalWithoutFilters >= threshold,
    )
    if (result.length === 1 && result[0].name === UNAFFORDABLE) return []

    return result
  }

  const getColor = (series, i, lenderName) => {
    if (series.yValues[0] === UNAFFORDABLE) return UNAFFORDABLE_COLOR
    if (series.yValues[0] === OTHERS) return OTHERS_COLOR
    if (series.yValues[0] === lenderName) return MY_LENDER_COLOR
    return colors[i % (colors.length - 1)]
  }

  const lenderName = Utils.Cube.isFilteredByCompany(vizState)
    ? null
    : (user.lenders.find((l) => l.id === currentLenderId) || {}).name

  //   Lender a = Ranked 1 500 cases out of 1000
  // Lender b = Ranked 1 for 300 cases out of 1000
  // Lender c = Ranked 1 for 200 cases out of 250.

  // Lender a = Ranked 2 400 cases out of 1000
  // Lender b = Ranked 2 for 550 cases out of 1000
  // Lender c = Ranked 2 for 50 cases out of 250.
  // result
  // Lender a - (500+400)/1000 = 90%
  // Lender b - (300+550)/1000 = 85%
  // Lender c - (200+50)/250 = 100%

  // As overall percentage.
  // Lender a = 90/(90 + 85 + 100)*100 = 32.73%
  // Lender b = 85/(90 + 85 + 100)*100 = 30.9%
  // Lender c = 100/(90 + 85 + 100)*100 = 36.36%

  const result = buildResult(resultSet.loadResponses)
  const seriesNames = buildSeries(result)

  if (vizState.chartType === 'topXTable') {
    return <Table data={result} vizState={vizState} />
  }

  return (
    <ResponsiveContainer height={height} width="100%">
      <BarChart data={result} margin={{ left: -10 }}>
        <XAxis
          axisLine={false}
          dataKey="x"
          minTickGap={20}
          tickFormatter={(v) => xAxisFormatter(v, vizState)}
          tickLine={false}
        />
        <YAxis
          axisLine={false}
          tickFormatter={(value) => yAxisFormatter(value, vizState)}
          tickLine
          ticks={[0, 25, 50, 75, 100]}
        />
        <CartesianGrid vertical={false} />
        {seriesNames.map((series, i) => (
          <Bar
            dataKey={series.key}
            fill={getColor(series, i, lenderName)}
            key={series.key}
            name={series.title}
            stackId="a"
          />
        ))}
        <Tooltip content={<CustomTooltip lenderName={lenderName} vizState={vizState} />} />
      </BarChart>
    </ResponsiveContainer>
  )
}

const CustomTooltipContainer = styled.div`
  text-align: center;
  padding: 30px 50px;
  background: white;
  border: 1px solid grey;
  `

const CustomTooltip = ({
  payload, label, lenderName, vizState,
}) => {
  if (!payload || !payload.length) return null

  const others = _.find(payload, (item) => item.dataKey.includes(OTHERS))
  const unaffordable = _.find(payload, (item) => item.dataKey.includes(UNAFFORDABLE))

  let lenders = _.filter(payload, (item) => !item.dataKey.includes(OTHERS) && !item.dataKey.includes(UNAFFORDABLE))
  lenders = _.sortBy(lenders, (lender) => -lender.value)

  return (
    <CustomTooltipContainer>
      <div><strong>{moment(label).format('MMMM, YYYY')}</strong></div>
      <br />
      {_.map(lenders, (item) => {
        const lender = item.dataKey
        return <div key={item.dataKey} style={{ color: item.fill }}>{`${lender} - ${Math.round(item.value)}%`}</div>
      })}
      {!vizState.lenders
      && !lenders.some((l) => l.dataKey === lenderName)
      && !Utils.Cube.isFilteredByCompany(vizState)
      && <div style={{ color: MY_LENDER_COLOR }}>{`${lenderName} - 0%`}</div>}
      <br />
      {unaffordable && (
        <div
          style={{ color: unaffordable.fill }}
        >
          {`Unaffordable cases - ${Math.round(unaffordable.value)}%`}
        </div>
      )}
      {others && <div style={{ color: others.fill }}>{`Others - ${Math.round(others.value)}%`}</div>}
    </CustomTooltipContainer>
  )
}

export default connector(TopXBarChart)

const Table = ({ data, vizState }) => {
  const getColumns = () => [
    { dataIndex: 'date', key: 'date', title: 'Date' },
    { dataIndex: 'name', key: 'name', title: 'Lender' },
    { dataIndex: 'count', key: 'count', title: 'Percents' },
  ]

  const sort = (a, b, { key }) => {
    if (key === 'count') return a[key] - b[key]

    return a[key].localeCompare(b[key])
  }

  const alteredData = _.reduce(data, (result, item) => {
    const arr = _.reduce(item, (res, value, key) => {
      if (['category', 'key', 'xValues', 'x'].includes(key)) return res
      return [...res, {
        date: item.category.split('T')[0], count: _.round(value, 2), name: key, key: uuidv4(),
      }]
    }, [])
    return [...result, ...arr]
  }, [])

  Utils.Report.setData(vizState.id, alteredData, getColumns())

  return (
    <AntTable
      columns={getColumns().map((c) => ({ ...c, sorter: (a, b) => sort(a, b, c) }))}
      dataSource={alteredData}
      pagination={false}
      style={{ height: '300px', overflowY: 'auto' }}
    />
  )
}
