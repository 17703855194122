import btlBenchmark from './btlBenchmark'
import market from './market'
import resiBroker from './resiBroker'
import btlBroker from './btlBroker'
import resiCommon from './resiCommon'
import btlCommon from './btlCommon'

export default {
  market,
  btlBenchmark,
  resiBroker,
  btlBroker,
  resiCommon,
  btlCommon,
}
