import _ from 'lodash'
import { setIn } from 'utils/immutable'
import dimensions from 'dimensions'

const UpdateItem = {
  run: (item, filters) => {
    const query = _.isArray(item.vizState.query)
      ? item.vizState.query.map((q, i) => UpdateItem.alertQuery(item, q, filters, i))
      : UpdateItem.alertQuery(item, item.vizState.query, filters)

    return setIn(item, ['vizState', 'query'], query)
  },
  isFilledFilter: (filter) => filter.operator && filter.values.length && filter.dimension,
  alertQuery: (item, query, filters, index) => {
    if (item.vizState.disabledFilters && item.vizState.disabledFilters.includes(index)) return query

    const measure = query.measures[0].split('.')[0]
    const unaffectedFilters = query.filters.filter((filter) => {
      if (!filter.dimension) return true

      const dimension = filter.dimension.split('.')[1]
      return !dimensions.some((dim) => dim.fieldForFilter === dimension)
    })
    const affectedFilters = filters.reduce((res, item) => {
      if (!UpdateItem.isFilledFilter(item)) return res

      return [...res, {
        dimension: `${measure}.${item.dimension}`,
        operator: item.operator,
        values: item.values,
      }]
    }, [])
    return { ...query, filters: [...unaffectedFilters, ...affectedFilters] }
  },
}

export default UpdateItem
