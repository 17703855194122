import { Form, Select } from 'antd'
import React from 'react'
import { updateGlobalOptions } from 'core/globalOptions'
import { useDispatch, useSelector } from 'react-redux'

const { Option } = Select

const DEFAULT_OPTIONS = [
  { label: 'Year', value: 'year' },
  { label: 'Month', value: 'month' },
  { label: 'Week', value: 'week' },
]

const GroupingByTime = () => {
  const dispatch = useDispatch()
  const value = useSelector(({ globalOptions }) => globalOptions.groupingByTime)
  const getDefaultValue = () => value

  const handleChange = (value) => {
    dispatch(updateGlobalOptions('groupingByTime', value))
  }

  const getOptions = () => DEFAULT_OPTIONS

  return (
    <Form.Item label="Grouping by Time">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select time"
      >
        {getOptions().map((option) => <Option key={option.value} value={option.value}>{option.label}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default GroupingByTime
