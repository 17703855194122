import { Form, Select } from 'antd'
import React from 'react'
import { getIn, setIn } from 'utils/immutable'

const { Option } = Select

const DEFAULT_OPTIONS = [
  { value: 'bar', label: 'Bar' },
  { value: 'line', label: 'Line' },
  { value: 'table', label: 'Table' },
]

const ChartType = ({ item, updateItem, option }) => {
  const getDefaultValue = () => getIn(item, ['vizState', 'chartType'])

  const handleChange = (value) => {
    const newItem = setIn(item, ['vizState', 'chartType'], value)
    updateItem(item.id, newItem)
  }
  const getOptions = () => option.options || DEFAULT_OPTIONS

  return (
    <Form.Item label="Chart Type">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select chart type"
      >
        {getOptions().map((opt) => <Option key={opt.value} value={opt.value}>{opt.label}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default ChartType
