import Utils from 'utils'
import { v4 as uuidv4 } from 'uuid'
import LocalStorage from 'utils/LocalStorage'
import VERSION from 'VERSION'

const getDefaultFilter = () => ({
  dimension: 'region',
  operator: 'equals',
  values: [],
  id: uuidv4(),
})

export const initialState = {
  buy_to_let: LocalStorage.getIn(['globalFilters', 'buy_to_let', VERSION]) || [getDefaultFilter()],
  residential: LocalStorage.getIn(['globalFilters', 'residential', VERSION]) || [getDefaultFilter()],
}

export const UPDATE_FILTERS = 'filters/UPDATE_FILTERS'

export const updateFilters = (filters, type) => ({
  type: UPDATE_FILTERS,
  payload: { filters, type },
})

const HANDLERS = {
  [UPDATE_FILTERS]: (state, { payload: { filters, type } }) => {
    LocalStorage.setIn(['globalFilters', type, VERSION], filters)
    return { ...state, [type]: filters }
  },
}

export default Utils.Redux.combineHandlers(HANDLERS, initialState)
