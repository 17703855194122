import React from 'react'
import { Form, Select } from 'antd'
import { updateGlobalOptions } from 'core/globalOptions'
import { useDispatch, useSelector } from 'react-redux'

const { Option } = Select

const FilterByTime = () => {
  const dispatch = useDispatch()
  const value = useSelector(({ globalOptions }) => globalOptions.filterByTime)
  const getDefaultValue = () => value || ''

  const handleChange = (value) => {
    dispatch(updateGlobalOptions('filterByTime', value))
  }

  return (
    <Form.Item label="Filter by Time">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select time"
      >
        <Option value="">All time</Option>
        <Option value="This month">This month</Option>
        <Option value="This quarter">This quarter</Option>
        <Option value="This year">This year</Option>
        <Option value="Last month">Last month</Option>
        <Option value="Last quarter">Last quarter</Option>
        <Option value="Last year">Last year</Option>
      </Select>
    </Form.Item>
  )
}

export default FilterByTime
