import React, { useState } from 'react'
import _ from 'lodash'
import { Card, Menu, Dropdown } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Utils from 'utils'
import CustomTitles from '../customTitles'
import styles from './DashboardItem.module.scss'
import EditModal from './EditModal'

const StyledCard = styled(Card)`
  box-shadow: 0px 2px 4px rgba(141, 149, 166, 0.1);
  border-radius: 4px;

  .ant-card-head {
    border: none;
  }
  .ant-card-body {
    padding-top: 0px;
  }
`

const DashboardItemDropdown = ({ item, setModal }) => {
  const handleExport = () => {
    // extract to independent file
    fetch(`${process.env.REACT_APP_ELIXIR_API_URL}/lender_portal/export`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: Utils.Report.getData(item.id) || [] }),
    }).then((res) => res.blob())
      .then((blob) => {
        const objUrl = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = objUrl
        link.download = `MBT Lender Portal ${item.name}.csv`
        link.click()

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => { window.URL.revokeObjectURL(objUrl) }, 250)
      })
  }
  const dashboardItemDropdownMenu = (
    <Menu>
      {item.options.length && (
        <Menu.Item onClick={() => setModal(true)}>Edit</Menu.Item>
      )}
      {_.toLower(item.vizState.chartType).includes('table') && <Menu.Item onClick={handleExport}>Export</Menu.Item>}
    </Menu>
  )
  return (
    <Dropdown
      overlay={dashboardItemDropdownMenu}
      placement="bottomLeft"
      trigger={['click']}
    >
      <MenuOutlined />
    </Dropdown>
  )
}

const DashboardItem = ({
  itemId, children, data, setData, item, setIsDraggable,
}) => {
  const [modal, updateModal] = useState(false)

  const setModal = (value) => {
    updateModal(value)
    setIsDraggable(!value)
  }

  const getTitle = () => {
    if (item.vizState.titleType) {
      const Title = CustomTitles[item.vizState.titleType]
      return <Title vizState={item.vizState} name={item.name} />
    }

    return _.template(item.name)(getTemplateOptions())
  }

  const getTemplateOptions = () => {
    if (!item.vizState.query) return {}

    const schema = Utils.Cube.getSchema(item.vizState)
    const topPosition = item.options.find((opt) => opt.name === 'top_position')
    const opts = {
      topPositionLabel: '${topPositionLabel}',
    }
    if (topPosition) {
      const option = topPosition.options.find((opt) => opt.value === schema)
      if (option) {
        opts.topPositionLabel = option.label
      }
    }
    return opts
  }

  const getDescription = () => _.template(item.description)(getTemplateOptions())

  return (
    <>
      <EditModal
        data={data}
        item={item}
        itemId={itemId}
        modal={modal}
        setData={setData}
        setModal={setModal}
        title={item.name}
      />
      <StyledCard
        bordered={false}
        extra={<DashboardItemDropdown item={item} itemId={itemId} setModal={setModal} />}
        style={{ height: '100%', width: '100%' }}
        bodyStyle={{ overflowY: 'auto', height: 'calc(100% - 48px)' }}
        title={getTitle()}
      >
        {item.description && <div className={styles.description}>{getDescription()}</div>}
        {children}
      </StyledCard>
    </>
  )
}

export default DashboardItem
