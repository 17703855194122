import { Form, Select } from 'antd'
import React from 'react'
import { getIn, setIn } from 'utils/immutable'
import Utils from 'utils'

const { Option } = Select

const DEFAULT_OPTIONS = [
  { value: 'initial_rate', label: 'Initial Rate' },
  { value: 'initial_cost_avg', label: 'Initial Rate Average' },
  { value: 'total_cost', label: 'Total Cost' },
]

const ScenarioOrderStrategy = ({ item, updateItem }) => {
  const getDefaultValue = () => getIn(item, ['vizState', 'scenarioOrderStrategy'])

  const handleChange = (value) => {
    let newItem = setIn(item, ['vizState', 'scenarioOrderStrategy'], value)
    const [count, affordableCount, average, ...rest] = item.vizState.query

    const newRest = rest.map((query) => Utils.Cube.updateOrAddFilter(query, 'scenarioOrderStrategy', value))
    newItem = setIn(item, ['vizState', 'query'], [count, affordableCount, average, ...newRest])
    updateItem(item.id, newItem)
  }

  return (
    <Form.Item label="Scenario Strategy">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select strategy"
      >
        {DEFAULT_OPTIONS.map((opt) => <Option key={opt.value} value={opt.value}>{opt.label}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default ScenarioOrderStrategy
