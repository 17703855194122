import React from 'react'
import { Row, Col, Form } from 'antd'
import CustomFilters from 'components/DashboardItem/options/CustomFilters'
import FilterByTime from 'components/DashboardItem/options/FilterByTime'
import GroupingByTime from 'components/DashboardItem/options/GroupingByTime'
import Reruns from 'components/DashboardItem/options/Reruns'

const PATHS_TO_TYPE = {
  '/broker-insights-resi': 'residential',
  '/broker-insights-btl': 'buy_to_let',
  '/': 'residential',
  '/btl-common': 'buy_to_let',
}

const GlobalFilters = () => (
  <Row align="top" justify="space-around" key="1" type="flex">
    <Col span={24}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <FilterByTime />
        <GroupingByTime />
        <Reruns />
        <CustomFilters type={PATHS_TO_TYPE[location.pathname]} />
      </Form>
    </Col>
  </Row>
)

export default GlobalFilters
