import { Form, Select, Tag } from 'antd'
import React, { useState } from 'react'
import { getIn, setIn } from 'utils/immutable'
import fetchOptions from 'core/fetchOptions'

const { Option } = Select

// TODO (atanych): come up with the unified solution between Lenders and LenderProducts
const Lenders = ({ updateItem, item }) => {
  const [options, setOptions] = useState([])
  const lenders = getIn(item, ['vizState', 'lenders']) || []
  const [selectedLenders, setSelectedLenders] = useState(lenders)

  const handleSearch = (value) => {
    if (value) {
      fetchOptions(
        '/lender_portal/lenders/search',
        { q: value },
        (data) => setOptions(data),
      )
    } else {
      setOptions([])
    }
  }

  const handleChange = (value) => {
    setSelectedLenders(value)
    const newItem = setIn(item, ['vizState', 'lenders'], value)
    updateItem(item.id, newItem)
  }

  const tagRender = ({ label, closable, onClose }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label[1]}
      </Tag>
    )
  }

  return (
    <div>
      <Form.Item label="Lender">
        <Select
          mode="multiple"
          showSearch
          value={selectedLenders}
          defaultValue={[]}
          placeholder="Select lender"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          tagRender={tagRender}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={<div>Not Found</div>}
        >
          {options.map((lender) => (
            <Option key={lender.id} value={[lender.id, lender.name]}>{lender.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export default Lenders
