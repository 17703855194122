import styled from 'styled-components'

export const YearsContainer = styled.div`
font-size: 12px;
text-align: right;
margin-top: 8px;
.ant-pagination {
  font-size: 12px;
  .ant-pagination-item {
    height: 18px;
    line-height: 18px;
  }
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  font-size: 10px;
}
.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
  min-width: 18px;
  height: 18px;
  margin: 0;
  line-height: 18px;
}
`
