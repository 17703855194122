import { Form, Select } from 'antd'
import Utils from 'utils'
import React from 'react'
import { getIn, setIn } from 'utils/immutable'

const { Option } = Select

const Measure = ({ item, updateItem }) => {
  const getDefaultValue = () => {
    const segment = getIn(item, ['vizState', 'query', 'segments', 0])
    return segment ? segment.split('.')[1] : ''
  }

  const handleChange = (value) => {
    const segments = value === '' ? [] : [`${Utils.Cube.getSchema(item.vizState)}.${value}`]
    updateItem(item.id, setIn(item, ['vizState', 'query', 'segments'], segments))
  }

  return (
    <Form.Item label="Measure">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select measure"
      >
        <Option value="">Total Cases</Option>
        <Option value="unaffordable">Unaffordable</Option>
        <Option value="affordable">Affordable</Option>
      </Select>
    </Form.Item>
  )
}

export default Measure
