import { Form, Select } from 'antd'
import React from 'react'
import { getIn, setIn } from 'utils/immutable'
import Utils from 'utils'

const { Option } = Select

const DEFAULT_OPTIONS = [
  'Fixed 2 - 4 year',
  'Fixed 5 - 9 year',
  'Fixed 10 year +',
  'Variable/Tracker/Discount (Loan Term)',
  'Tracker/Discount 2 year',
  'Tracker/Discount 3 year',
]

const ProductType = ({ item, updateItem }) => {
  const getDefaultValue = () => getIn(item, ['vizState', 'productType'])

  const handleChange = (value) => {
    let newItem = setIn(item, ['vizState', 'productType'], value)
    const [count, affordableCount, average, ...rest] = item.vizState.query

    const newRest = rest.map((query) => Utils.Cube.updateOrAddFilter(query, 'scenario', value))
    newItem = setIn(item, ['vizState', 'query'], [count, affordableCount, average, ...newRest])
    updateItem(item.id, newItem)
  }

  return (
    <Form.Item label="Product Type">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select product type"
      >
        {DEFAULT_OPTIONS.map((opt) => <Option key={opt} value={opt}>{opt}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default ProductType
