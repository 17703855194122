import { Form, Select } from 'antd'
import HasAccess from 'core/HasAccess'
import React from 'react'
import { updateGlobalOptions } from 'core/globalOptions'
import { useDispatch, useSelector } from 'react-redux'

const { Option } = Select

const Reruns = () => {
  const dispatch = useDispatch()
  const value = useSelector(({ globalOptions }) => globalOptions.reRuns)
  const permissions = useSelector(({ user: { permissions } }) => permissions)

  if (!HasAccess.run(permissions, 'lpNewFeature')) return null

  const getDefaultValue = () => value

  const handleChange = (value) => dispatch(updateGlobalOptions('reRuns', value))

  return (
    <Form.Item label="Re-runs">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select"
      >
        <Option value="with">Including</Option>
        <Option value="without">Excluding</Option>
      </Select>
    </Form.Item>
  )
}

export default Reruns
