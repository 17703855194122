import React from 'react'
import { Button, Select, Input } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import HasAccess from 'core/HasAccess'
import { connect } from 'react-redux'

const { Option } = Select

const DEFAULT_OPERATOR = 'equals'

const OPERATORS = {
  string: [{ value: 'equals', label: '=' }, { value: 'notEquals', label: '!=' }],
  number: [
    { value: 'lt', label: '<' },
    { value: 'lte', label: '≤' },
    { value: 'equals', label: '=' },
    { value: 'notEquals', label: '!=' },
    { value: 'gt', label: '>' },
    { value: 'gte', label: '≥' },
  ],
}

const connector = connect(({ user: { permissions } }) => ({ permissions }), {})

const CustomFilter = ({
  dimensions, filter, size, onAdd, onRemove, onUpdate, type: itemType, permissions,
}) => {
  const dimensionConfig = dimensions.find((dimension) => dimension.fieldForFilter === filter.dimension) || {}
  const type = dimensionConfig.type || 'string'
  const ValueComponent = VALUES[type]
  const isDisabledAddButton = () => {
    if (HasAccess.run(permissions, 'lpMultiFilters')) return false

    if (HasAccess.run(permissions, 'lpTwoFilters') && size < 2) return false

    if (HasAccess.run(permissions, 'lpThreeFilters') && size < 3) return false

    return true
  }
  return (
    <div style={{ display: 'flex', marginTop: '10px' }}>
      <Input.Group compact style={{ marginRight: '20px' }}>
        <Select
          style={{ width: '45%' }}
          value={filter.dimension}
          defaultValue=""
          onChange={(dimension) => onUpdate(filter, { dimension, values: [], operator: DEFAULT_OPERATOR })}
          placeholder="Select dimension"
        >
          <Option value="">Empty</Option>
          {dimensions.map((d) => <Option key={d.fieldForFilter} value={d.fieldForFilter}>{d.title}</Option>)}
        </Select>
        <Select
          style={{ width: '15%' }}
          value={filter.operator}
          onChange={(operator) => onUpdate(filter, { operator })}
          defaultValue={DEFAULT_OPERATOR}
        >
          {OPERATORS[type].map((option, index) => <Option key={index} value={option.value}>{option.label}</Option>)}
        </Select>
        <ValueComponent dimensionConfig={dimensionConfig} onUpdate={onUpdate} filter={filter} type={itemType} />
      </Input.Group>
      <Button
        onClick={onAdd}
        icon={<PlusOutlined />}
        shape="circle"
        disabled={isDisabledAddButton()}
      />
      <Button onClick={() => onRemove(filter)} disabled={size === 1} icon={<MinusOutlined />} shape="circle" />
    </div>
  )
}

const StringValue = ({
  dimensionConfig, onUpdate, filter, type,
}) => {
  const getValues = () => {
    if (!dimensionConfig.values) return []
    return dimensionConfig.values.filter((v) => !v.intendedFor || v.intendedFor === type)
  }

  return (
    <Select
      value={filter.values}
      mode="multiple"
      style={{ width: '40%' }}
      placeholder="Select Value"
      onChange={(values) => onUpdate(filter, { values })}
    >
      {getValues().map(
        (value, index) => <Option key={index} value={value.label}>{value.label}</Option>,
      )}
    </Select>
  )
}

const NumberValue = ({ onUpdate, filter }) => (
  <Input
    style={{ width: '40%' }}
    value={filter.values[0]}
    placeholder="Value"
    onChange={(e) => onUpdate(filter, { values: [e.target.value] })}
  />
)

const VALUES = {
  string: StringValue,
  number: NumberValue,
}

export default connector(CustomFilter)
