import axios from 'axios'
import queryString from 'qs'
import humps from 'humps'

const LOADING = 'LOADING'
const LOADING_COMPLETE = 'LOADING_COMPLETE'

export const buildUrl = ({ method = 'get', url, body }) => {
  if (method !== 'get') return `${process.env.REACT_APP_ELIXIR_API_URL}${url}`

  const normalizedBody = humps.decamelizeKeys(body)

  return `${process.env.REACT_APP_ELIXIR_API_URL}${url}?${queryString.stringify(normalizedBody, {
    arrayFormat: 'bracket',
  })}`
}

const buildOptions = ({ options = {} }) => ({
  ...options,
  headers: {
    ...options.headers,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const apiMiddleware = () => (next) => (action) => {
  if (!action || !action.request) return next(action)

  const {
    request,
    request: { method = 'get', body = {}, loader },
  } = action
  const REQUEST = `${action.type}_REQUEST`
  const SUCCESS = action.type
  const FAILURE = `${action.type}_FAILURE`

  next({ ...action, type: REQUEST })
  if (loader) {
    next({ type: LOADING, payload: { name: SUCCESS } })
  }

  const processApi = () => axios
    .request({
      method,
      url: buildUrl(request),
      data: humps.decamelizeKeys(body),
      ...buildOptions(request),
      responseType: 'json',
      withCredentials: true,
    })
    .then(({ data }) => next({
      type: SUCCESS,
      response: humps.camelizeKeys(data),
      requestAction: action,
    }))
    .catch((error) => {
      if (!error.response || error.response.status === 401) {
        // window.location.href = `${process.env.REACT_APP_MAIN_PORTAL_URL}/signin`
      } else {
        next({ type: FAILURE, errors: humps.camelizeKeys(error) })
        throw error
      }
    })
    .finally(() => {
      if (loader) {
        next({ type: LOADING_COMPLETE })
      }
    })

  return processApi()
}

export default apiMiddleware
