import LocalStorage from 'utils/LocalStorage'
import Utils from 'utils'
import { getIn } from 'utils/immutable'
import { SET_USER } from './user'

export const initialState = LocalStorage.getIn(['current_lender_id'])

const UPDATE_CURRENT_LENDER_ID = 'UPDATE_CURRENT_LENDER_ID'

const NO_LENDER = 'NO_LENDER'

export const updateCurrentLenderId = (id) => ({
  type: UPDATE_CURRENT_LENDER_ID,
  payload: {
    id,
  },
})

const HANDLERS = {
  [UPDATE_CURRENT_LENDER_ID]: (state, { payload: { id } }) => id,
  [SET_USER]: (state, { response: { data } }) => state || getIn(data.lenders[0], 'id') || NO_LENDER,
}

export default Utils.Redux.combineHandlers(HANDLERS, initialState)
