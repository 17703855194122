import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import api from './middleware/api'
import reducer from './rootReducer'

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api, logger),
})

export default store
