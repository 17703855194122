import _ from 'lodash'
import { setIn } from 'utils/immutable'
import Utils from 'utils'

const Cube = {
  WITHOUT_RERUNS_SUF: 'WithoutReruns',
  BTL: 'buy_to_let',
  RESI: 'residential',

  getSchema: (vizState) => {
    if (_.isArray(vizState.query)) return vizState.query[0].measures[0].split('.')[0]

    return vizState.query.measures[0].split('.')[0]
  },

  getSchemaByQuery: (query) => query.measures[0].split('.')[0],

  getType: (item) => {
    const filters = _.isArray(item.vizState.query) ? item.vizState.query[0].filters : item.vizState.query.filters
    if ((filters || []).some(
      (filter) => filter.values && filter.values.includes(Cube.BTL),
    )) return Cube.BTL

    return Cube.RESI
  },

  updateOrAddFilter: (query, filterName, value) => {
    const schema = Utils.Cube.getSchemaByQuery(query)
    let filters = query.filters.map((filter) => {
      if (filter.dimension !== `${schema}.${filterName}`) return filter
      return { ...filter, values: [value] }
    })
    if (!filters.find((filter) => filter.dimension === `${schema}.${filterName}`)) {
      filters = [...filters, {
        dimension: `${schema}.${filterName}`,
        operator: 'equals',
        values: [value],
      }]
    }
    return { ...query, filters }
  },

  removeFilter: (query, filterName) => {
    const schema = Utils.Cube.getSchemaByQuery(query)
    const filters = query.filters.filter((filter) => filter.dimension !== `${schema}.${filterName}`)
    return { ...query, filters }
  },

  isPercentage: ({ dataFormat, query }) => dataFormat === 'percentage' && query.dimensions[0],

  percentageData: (data, vizState) => {
    const schema = Cube.getSchema(vizState)

    return data.map((item) => {
      const values = _.map(item, (value, key) => {
        if (!key.includes(schema)) return null

        return value
      })
      const total = _.sum(values)

      return _.reduce(item, (res, value, key) => {
        if (!key.includes(schema)) return res
        return { ...res, [key]: ((value * 100) / total) || 0 }
      }, item)
    })
  },

  getDimensions: ({ query }) => query.dimensions || query[0].dimensions,

  isFilteredByCompany: ({ query }) => {
    const filters = _.isArray(query) ? query[0].filters : query.filters

    return _.some(filters, (filter) => _.isString(filter) && filter.includes('COMPANIES'))
  },

  getTimeDimension: ({ query }) => (query.timeDimensions || query[0].timeDimensions)[0].granularity,

  updateQuery: (item, alterFn, value) => {
    if (_.isArray(item.vizState.query)) {
      const queries = item.vizState.query.map((query) => alterFn(query, value))
      return setIn(item, ['vizState', 'query'], queries)
    }

    return setIn(item, ['vizState', 'query'], alterFn(item.vizState.query, value))
  },

  isWithoutReruns: (item) => item.vizState.query[0].measures[0].includes(Cube.WITHOUT_RERUNS_SUF),
}

export default Cube
