const all = [
  'Landbay',
  'WestBrom Building Society',
  'Santander',
  'Al Rayan Bank',
  'Newbury Building Society',
  'Newcastle Building Society',
  'Metro',
  'Woolwich',
  'Interbay',
  'NatWest',
  'Ipswich Building Society',
  'The Mortgage Lender',
  'Bank of China',
  'LendInvest',
  'Tipton & Coseley Building Society',
  'Precise Mortgages',
  'Stafford Railway Building Society',
  'Tesco',
  'M&S Bank',
  'Vernon',
  'Ulster',
  'Virgin',
  'Scottish Widows',
  'Accord',
  'Barclays',
  'Clydesdale',
  'Cambridge Building Society',
  'Ecology Building Society',
  'First Trust Bank',
  'Cynergy Bank',
  'Habito',
  'Swansea Building Society',
  'Norwich and Peterborough Building Society',
  'Mortgage Trust',
  'Gatehouse',
  'Norton',
  'Earl Shilton Building Society',
  'Market Harborough',
  'Mercantile Trust',
  'Molo',
  'Zephyr',
  'Secure Trust Bank',
  'Octane Capital',
  'OakNorth Commercial',
  'BuildLoan',
  'Pure Retirement',
  'Mint Bridging',
  'Hampshire Trust Bank',
  'LV=',
  'More 2 Life',
  'One Family',
  'MT Finance',
  'Sainsburys Bank',
  'Hanley Economic Building Society',
  'Furness Building Society',
  'Loughborough Building Society',
  'Saffron',
  'Teachers Building Society',
  'Kensington',
  'Nationwide',
  'Principality',
  'Kent Reliance',
  'Leeds Building Society',
  'Chelsea',
  'Platform',
  'Skipton Building Society',
  'Nottingham Building Society',
  'PostOffice',
  'Coventry Building Society',
  'The Melton Building Society',
  'TSB',
  'Aldermore',
  'Mansfield Building Society',
  'Vida Homeloans',
  'Buckinghamshire Building Society',
  'Keystone',
  'Darlington Building Society',
  'Marsden Building Society',
  'Masthaven',
  'Paragon Bank Plc',
  'Bluestone Mortgages',
  'Shawbrook Bank',
  'West One',
  'Ahli United Bank',
  'United Trust Bank',
  'Reliance Bank',
  'MBS Lending Ltd',
  'Cambridge & Counties Bank',
  'Beverly',
  'Castle Trust',
  'Harpenden',
  'Hodge',
  'Central Trust',
  'Danske Bank',
  'Investec',
  'Prestige',
  '1st Stop Home Loans',
  'AIB',
  'Alternative Bridging Corporation',
  'Equifinance',
  'Evolution Money',
  'FinSec',
  'Pepper Money',
  'HSBC',
  'Monmouthshire Building Society',
  'First Direct',
  'Foundation Home Loans',
  'Funding 365',
  'Greenfield Mortgages',
  'Legal & General Home Finance',
  'Lesley Stephen & Co',
  'Lloyds Bank',
  'Octopus Property',
  'Royal Bank Of Scotland',
  'Skipton International',
  'Spring Finance',
  'Step One Finance',
  'Yorkshire Bank',
  'Yorkshire Building Society',
  'Family Building Society',
  'Chorley Building Society',
  'Hinckley & Rugby Building Society',
  'Bank of Ireland',
  'Fleet Mortgages',
  'Halifax',
  'Scottish Building Society',
  'Together',
  'Dudley Building Society',
  'Penrith Building Society',
  'Affirmative',
  'State Bank of India',
  'Just',
]

const RESI = [
  {
    name: 'All',
    lenders: all,
  },
  {
    name: 'Mainstream',
    lenders: [
      'Santander',
      'NatWest',
      'Virgin',
      'Scottish Widows',
      'Accord',
      'Barclays',
      'Clydesdale',
      'Nationwide',
      'Principality',
      'Leeds Building Society',
      'Platform',
      'Skipton Building Society',
      'PostOffice',
      'Coventry Building Society',
      'TSB',
      'HSBC',
      'Bank of Ireland',
      'Halifax',
    ],
  },
  {
    name: 'Building Society',
    lenders: [
      'WestBrom Building Society',
      'Newbury Building Society',
      'Newcastle Building Society',
      'Ipswich Building Society',
      'Tipton & Coseley Building Society',
      'Cambridge Building Society',
      'Hanley Economic Building Society',
      'Furness Building Society',
      'Saffron',
      'Teachers Building Society',
      'Nottingham Building Society',
      'Monmouthshire Building Society',
      'Family Building Society',
      'Hinckley & Rugby Building Society',
    ],
  },
  {
    name: 'Specialist',
    lenders: [
      'Precise Mortgages',
      'Kensington',
      'Kent Reliance',
      'Aldermore',
      'Foundation Home Loans',
      'Pepper Money',
    ],
  },
]

const BTL = [
  {
    name: 'All',
    lenders: all,
  },
  {
    name: 'Mainstream',
    lenders: [
      ...RESI.find((item) => item.name === 'Mainstream').lenders,
      'Metro',
    ],
  },
  {
    name: 'Building Society',
    lenders: [
      ...RESI.find((item) => item.name === 'Building Society').lenders,
      'Loughborough Building Society',
      'Chorley Building Society',
      'Mansfield Building Society',
      'Buckinghamshire Building Society',
      'Darlington Building Society',
      'Marsden Building Society',
      'The Melton Building Society',
      'Scottish Building Society',
      'Stafford Railway Building Society',
      'Cambridge & Counties Bank',
    ],
  },
  {
    name: 'Specialist',
    lenders: [
      ...RESI.find((item) => item.name === 'Specialist').lenders,
      'Al Rayan Bank',
      'Interbay',
      'The Mortgage Lender',
      'Vida Homeloans',
      'Bank of China',
      'Fleet Mortgages',
      'Keystone',
      'Landbay',
      'LendInvest',
      'Paragon Bank Plc',
      'Bluestone Mortgages',
      'Shawbrook Bank',
      'Together',
      'West One',
      'Habito',
      'Gatehouse',
    ],
  },
]

export default {
  residential: RESI,
  buy_to_let: BTL,
}
