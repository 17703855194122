import Utils from 'utils'
import _ from 'lodash'

export const initialState = {
  lenders: [],
  roles: [],
}

export const SET_USER = 'user/SET_USER'

export const setUser = (amount) => ({
  type: SET_USER,
  payload: amount,
  request: {
    url: '/lender_portal/me',
    loader: true,
  },
})

const HANDLERS = {
  [SET_USER]: (state, { response }) => {
    const lenders = _.sortBy(response.data.lenders, (l) => l.name)
    return { ...state, ...response.data, lenders }
  },
}

export default Utils.Redux.combineHandlers(HANDLERS, initialState)
