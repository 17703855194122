import axios from 'axios'
import { buildUrl } from 'core/middleware/api'

let timeout
let currentValue

export default function fetch (url, payload, callback) {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
  currentValue = payload.q

  function runRequest () {
    axios.request({
      method: 'get',
      url: buildUrl({ body: payload, url }),
      responseType: 'json',
      withCredentials: true,
    }).then((response) => {
      if (currentValue === payload.q) {
        const { data } = response
        callback(data)
      }
    })
  }

  timeout = setTimeout(runRequest, 300)
}
