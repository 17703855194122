import { Form, Select } from 'antd'
import React from 'react'
import { getIn, setIn } from 'utils/immutable'

const { Option } = Select

const DataFormat = ({ item, updateItem, option }) => {
  const getDefaultValue = () => getIn(item, ['vizState', 'dataFormat'])

  const handleChange = (value) => {
    const alteredItem = setIn(item, ['vizState', 'dataFormat'], value)
    updateItem(item.id, alteredItem)
  }

  return (
    <Form.Item label="Data Format">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select Data Format"
      >
        <Option value="absolute">Absolute</Option>
        {option.options.includes('index')
         && !item.vizState.query.dimensions.length
         && <Option value="index">Index</Option>}
        {option.options.includes('percentage') && (
          <Option
            disabled={!item.vizState.query.dimensions.length}
            value="percentage"
          >
            Percentage
          </Option>
        )}
      </Select>
    </Form.Item>
  )
}

export default DataFormat
