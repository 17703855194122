const colors = [
  '#1caf9a',
  '#7DB3FF',
  '#FF7C78',
  '#49457B',
  '#dc3d3d',
  '#1b82f1',
  '#95b75d',
  '#F8B195',
  '#F67280',
  '#C06C84',
  '#6C5B7B',
  '#355C7D',
  '#99B898',
  '#FECEAB',
  '#FF847C',
  '#E84A5F',
  '#2A363B',
  '#A8E6CE',
  '#DCEDC2',
  '#FFD3B5',
  '#FFAAA6',
  '#FF8C94',
]

export default colors
