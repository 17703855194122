import { Form, Select } from 'antd'
import React, { useState } from 'react'
import { getIn, setIn } from 'utils/immutable'
import fetchOptions from 'core/fetchOptions'
import _ from 'lodash'
import Utils from 'utils'

const { Option } = Select

const LENDER_NUMBER = 3
const SHIFT = 4

const LendersProducts = ({ item, updateItem }) => (
  _.times(LENDER_NUMBER, (index) => <LenderProduct key={index} index={index} item={item} updateItem={updateItem} />)
)

const LenderProduct = ({ index, updateItem, item }) => {
  const [lenderData, setLenderData] = useState([])
  const [lenderValue, setLenderValue] = useState()
  const [productData, setProductData] = useState([])
  const [productValue, setProductValue] = useState()
  const lenderProduct = getIn(item, ['vizState', 'lendersProducts', index])

  const handleLenderSearch = (value) => {
    if (value) {
      fetchOptions(
        '/lender_portal/lenders/search',
        { q: value },
        (data) => setLenderData(data),
      )
    } else {
      setLenderData([])
    }
  }

  const handleProductSearch = (value) => {
    if (value) {
      fetchOptions(
        '/lender_portal/products/search',
        { q: value, lenderId: lenderProduct.lender[0] },
        (data) => setProductData(data),
      )
    } else {
      setProductData([])
    }
  }

  const getDefaultLender = () => getIn(item, ['vizState', 'lendersProducts', index, 'lender']) || {}
  const getDefaultProduct = () => getIn(item, ['vizState', 'lendersProducts', index, 'product']) || {}

  const handleLenderChange = (value) => {
    setLenderValue(value[1])
    let newItem = setIn(item, ['vizState', 'lendersProducts', index], { ...lenderProduct, lender: value })
    const currentIndex = SHIFT + index
    let query = item.vizState.query[currentIndex]
    query = Utils.Cube.updateOrAddFilter(query, 'lenderId', `${value[0]}`)
    newItem = setIn(newItem, ['vizState', 'query', currentIndex], query)
    updateItem(item.id, newItem)
  }

  const handleProductChange = (value) => {
    setProductValue(value[1])
    let newItem = setIn(item, ['vizState', 'lendersProducts', index], { ...lenderProduct, product: value })
    const currentIndex = SHIFT + index
    let query = item.vizState.query[currentIndex]
    if (value[0] === null) {
      query = Utils.Cube.removeFilter(query, 'productId')
    } else {
      query = Utils.Cube.updateOrAddFilter(query, 'productId', `${value[0]}`)
    }
    newItem = setIn(newItem, ['vizState', 'query', currentIndex], query)
    updateItem(item.id, newItem)
  }

  return (
    <div>
      <div style={{ testAlign: 'center' }}>{`Comparison ${index + 1}`}</div>
      <Form.Item label="Lender">
        <Select
          showSearch
          value={lenderValue}
          defaultValue={getDefaultLender()[1]}
          placeholder="Select lender"
          defaultActiveFirstOption={false}
          showArrow={false}
          disabled={index !== 0 && !getIn(item, ['vizState', 'lendersProducts', index - 1, 'lender', 1])}
          filterOption={false}
          onSearch={handleLenderSearch}
          onChange={handleLenderChange}
          notFoundContent={<div>Not Found</div>}
        >
          {lenderData.map((lender) => (
            <Option key={lender.id} value={[lender.id, lender.name]}>{lender.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Product">
        <Select
          showSearch
          value={productValue}
          disabled={!getIn(lenderProduct, ['lender', 0])}
          defaultValue={getDefaultProduct()[1]}
          placeholder="Select product"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleProductSearch}
          onChange={handleProductChange}
          notFoundContent={<div>Not Found</div>}
        >
          {productData.map((product) => (
            <Option key={product.id} value={[product.id, product.name]}>{product.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export default LendersProducts
