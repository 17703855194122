import React, { useState } from 'react'
import _ from 'lodash'
import { Table, Pagination } from 'antd'
import {
  DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined,
} from '@ant-design/icons'
import Utils from 'utils'
import dimensionConfig from 'dimensions'
import moment from 'moment'
import { YearsContainer } from './styles'

const getColumns = ({ title }, timeDimension) => [
  {
    dataIndex: 'dimension',
    key: 'dimension',
    title,
  },
  {
    dataIndex: timeDimension,
    key: timeDimension,
    title: _.upperFirst(timeDimension),
  },
  {
    dataIndex: 'averageLendersAffordable',
    key: 'averageLendersAffordable',
    title: 'Average no. of affordable lenders',
  },
  // {
  //   dataIndex: 'avgSpread',
  //   key: 'avgSpread',
  //   title: 'Average spread  (difference between highest and lowest loan offered)',
  // },
  {
    dataIndex: 'averageMinAffordable',
    key: 'averageMinAffordable',
    title: 'Lowest loan offered that meets loan requested',
  },
  {
    dataIndex: 'averageMaxAffordable',
    key: 'averageMaxAffordable',
    title: 'Highest loan offered that meets loan requested',
  },
  {
    dataIndex: 'affordablePercentage',
    key: 'affordablePercentage',
    title: '% of cases you were affordable (met loan requested)',
  },
  {
    dataIndex: 'averageRank',
    key: 'averageRank',
    title: 'Your average rank',
  },
  {
    dataIndex: 'ranked1Percentage',
    key: 'ranked1Percentage',
    title: '% of cases you ranked #1',
  },
]

const get = (response, key) => response[`CaseResults.${key}`] || response[`CaseResultsWithoutReruns.${key}`]

const normalizeData = (loadResponses, dimension, timeDimension) => {
  if (!loadResponses[0].data[0]) return []
  if (!get(loadResponses[0].data[0], dimension)) return []
  if (!loadResponses[0].data[0][`time.${timeDimension}`]) return []

  const lenderResponse = _.keyBy(
    loadResponses[1].data, (response) => [response[`time.${timeDimension}`], get(response, dimension)],
  )
  const rawData = loadResponses[0].data.reduce((res, item) => {
    const key = String([item[`time.${timeDimension}`], get(item, dimension)])
    return [...res, { common: item, lender: lenderResponse[key] }]
  }, [])

  const result = rawData.map((item) => {
    if (!item.lender) return null

    const rank1 = (get(item.lender, 'sumRankWeight') || 0) / (get(item.common, 'sumRankWeight') || 1)
    return {
      affordablePercentage: `${Math.round(get(item.lender, 'affordablePercentage'))}%`,
      averageLendersAffordable: Math.round(get(item.common, 'averageLendersAffordable')) || '-',
      averageMaxAffordable: formatter.format(Math.round(get(item.common, 'averageMaxAffordable'))),
      averageMinAffordable: formatter.format(Math.round(get(item.common, 'averageMinAffordable'))),
      averageRank: Math.round(get(item.lender, 'averageRank')) || '-',
      dimension: get(item.common, dimension),
      year: (new Date(item.common['time.year'])).getFullYear(),
      month: item.common['time.month'] && moment(item.common['time.month']).format("MMM'YY"),
      // avgSpread: `${Math.round(
      // eslint-disable-next-line max-len
      //   ((get(item.common, 'averageMaxAffordable') - get(item.common, 'averageMinAffordable')) * 100) / get(item.common, 'averageBorrowing'),
      // )}%`,
      ranked1Percentage: `${_.round(rank1 * 100, 1)}%`,
    }
  })
  return result.filter((r) => r)
}

const BenchmarkTable = ({ resultSet, vizState }) => {
  const dimension = Utils.Cube.getDimensions(vizState)[0].split('.')[1]
  const config = dimensionConfig.find((dim) => dim.field === dimension)
  const timeDimension = Utils.Cube.getTimeDimension(vizState)

  const getPeriods = (data) => _.uniqBy(data, (item) => item[timeDimension]).map((item) => item[timeDimension])

  const getFilteredData = (data, period) => {
    if (!data.length) return []

    const ordering = config.ordering || _.uniq(data.map((item) => item.dimension)).sort()

    return ordering.map(
      (bracket) => data.find((r) => r.dimension === bracket && r[timeDimension] === period) || {},
    )
  }
  const data = normalizeData(resultSet.loadResponses, dimension, timeDimension)
  const periods = getPeriods(data)

  const [period, setPeriod] = useState(_.last(periods))

  const filteredData = getFilteredData(data, period)
  const columns = getColumns(config, timeDimension)

  Utils.Report.setData(vizState.id, filteredData, columns)

  return (
    <>
      <div>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          rowKey={(item) => `${item[timeDimension]}-${item.dimension}`}
        />
      </div>
      <YearsContainer>
        <Pagination
          current={_.indexOf(periods, period) + 1}
          onChange={(v) => setPeriod(periods[v - 1])}
          total={periods.length}
          pageSize={1}
          size="small"
          itemRender={(page, type) => {
            const prefixCls = 'ant-pagination'
            const ellipsis = <span className={`${prefixCls}-item-ellipsis`}>•••</span>
            if (type === 'jump-prev') {
              return (
                <a className={`${prefixCls}-item-link`}>
                  <div className={`${prefixCls}-item-container`}>
                    <DoubleLeftOutlined className={`${prefixCls}-item-link-icon`} />
                    {ellipsis}
                  </div>
                </a>
              )
            }
            if (type === 'jump-next') {
              return (
                <a className={`${prefixCls}-item-link`}>
                  <div className={`${prefixCls}-item-container`}>
                    <DoubleRightOutlined className={`${prefixCls}-item-link-icon`} />
                    {ellipsis}
                  </div>
                </a>
              )
            }

            if (type === 'prev') {
              return (
                <button className={`${prefixCls}-item-link`} type="button" tabIndex={-1}>
                  <LeftOutlined />
                </button>
              )
            }

            if (type === 'next') {
              return (
                <button className={`${prefixCls}-item-link`} type="button" tabIndex={-1}>
                  <RightOutlined />
                </button>
              )
            }
            return <a rel="nofollow">{periods[page - 1]}</a>
          }}
        />
      </YearsContainer>
    </>
  )
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export default BenchmarkTable
