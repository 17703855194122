import React, { useState } from 'react'
import RGL, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import styled from 'styled-components'
import dragBackground from './drag-background.svg'

const ReactGridLayout = WidthProvider(RGL)

const DragField = styled(ReactGridLayout)`
  margin: 16px 28px 50px 28px;
  ${(props) => (props.isDragging ? `
    background: url(${dragBackground});
    background-repeat: repeat-y;
    background-position: 0px -4px;
    background-size: 100% 52px;
  ` : '')};
`

const Dashboard = ({
  children, dashboardItems, setData, isDraggable,
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const onLayoutChange = (newLayout) => {
    const newItems = dashboardItems.map((item) => {
      const layout = newLayout.find((l) => l.i === item.id.toString())
      if (!layout) return item

      return {
        ...item,
        layout: {
          x: layout.x, y: layout.y, w: layout.w, h: layout.h,
        },
      }
    })
    setData(newItems)
  }

  return (
    <DragField
      isDraggable={isDraggable}
      cols={24}
      containerPadding={[0, 0]}
      isDragging={isDragging}
      margin={[12, 12]}
      onDragStart={() => setIsDragging(true)}
      onDragStop={() => setIsDragging(false)}
      onLayoutChange={onLayoutChange}
      onResizeStart={() => setIsDragging(true)}
      onResizeStop={() => setIsDragging(false)}
      rowHeight={40}
    >
      {children}
    </DragField>
  )
}

export default Dashboard
