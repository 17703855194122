import { Form, Select } from 'antd'
import Utils from 'utils'
import React from 'react'
import { getIn, setIn } from 'utils/immutable'

const { Option } = Select

const TopPosition = ({ item, updateItem, option }) => {
  const getDefaultValue = () => {
    const dimension = getIn(
      item, ['vizState', 'query', 'dimensions', 0],
    ) || getIn(item, ['vizState', 'query', 0, 'dimensions', 0])
    return dimension.split('.')[0].split(Utils.Cube.WITHOUT_RERUNS_SUF)[0]
  }

  const handleChange = (val) => {
    const value = Utils.Cube.isWithoutReruns(item) ? `${val}${Utils.Cube.WITHOUT_RERUNS_SUF}` : val
    const dimensions = [`${value}.lenderName`]
    let newItem = setIn(item, ['vizState', 'query', 0, 'dimensions'], dimensions)
    const measures = [`${value}.count`]
    newItem = setIn(newItem, ['vizState', 'query', 0, 'measures'], measures)
    newItem = setIn(newItem, ['vizState', 'query', 0, 'timeDimensions', 0, 'dimension'], `${value}.updatedAt`)
    const newFilters = getIn(newItem, ['vizState', 'query', 0, 'filters']).map((f) => {
      const dimension = f.dimension.split('.')[1]
      return {
        ...f, dimension: `${value}.${dimension}`,
      }
    })

    newItem = setIn(newItem, ['vizState', 'query', 0, 'filters'], newFilters)
    updateItem(item.id, newItem)
  }

  const getOptions = () => option.options

  return (
    <Form.Item label="Top Position">
      <Select
        defaultValue={getDefaultValue()}
        onChange={handleChange}
        placeholder="Select Position"
      >
        {getOptions().map((option, index) => <Option value={option.value} key={index}>{option.label}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default TopPosition
