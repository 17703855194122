import { Form, Select } from 'antd'
import React, { useState } from 'react'
import { getIn, setIn } from 'utils/immutable'
import fetchOptions from 'core/fetchOptions'
import Utils from 'utils'

const { Option } = Select

const Product = ({ item, updateItem }) => {
  const [data, setData] = useState([])
  const [value, setValue] = useState()

  const handleSearch = (value) => {
    if (value) {
      fetchOptions(
        '/lender_portal/products/search',
        { q: value, lenderId: getIn(item, ['vizState', 'currentLenderId']) },
        (data) => setData(data),
      )
    } else {
      setData([])
    }
  }

  const getDefaultValue = () => getIn(item, ['vizState', 'product']) || {}

  const handleChange = (value) => {
    setValue(value[1])
    let newItem = setIn(item, ['vizState', 'product'], value)
    const [count, affordableCount, average, myStats, ...rest] = item.vizState.query

    const newMyStats = [myStats]
      .map((query) => {
        if (value[0] === null) {
          return Utils.Cube.removeFilter(query, 'productId')
        }
        return Utils.Cube.updateOrAddFilter(query, 'productId', `${value[0]}`)
      })
    newItem = setIn(item, ['vizState', 'query'], [count, affordableCount, average, ...newMyStats, ...rest])
    updateItem(item.id, newItem)
  }

  const options = data.map((product) => (
    <Option key={product.id} value={[product.id, product.name]}>
      {product.name}
    </Option>
  ))

  return (
    <Form.Item label="Product">
      <Select
        showSearch
        value={value}
        defaultValue={getDefaultValue()[1]}
        placeholder="Select product"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={<div>Not Found</div>}
      >
        {options}
      </Select>
    </Form.Item>
  )
}

export default Product
