import React from 'react'

const TopXLenders = ({ vizState }) => {
  const getName = () => {
    const chart = vizState.query[0].measures[0].split('.')[0]
    const position = chart.match(/\d+/)[0]
    if (chart.includes('Among')) return `Among-${position} Lenders`

    return `TOP-${position} Lenders`
  }

  const getTitle = () => [getName()].filter((i) => i).join(' / ')

  return <div>{getTitle()}</div>
}

export default TopXLenders
