import { Form, Select } from 'antd'
import _ from 'lodash'
import Utils from 'utils'
import React from 'react'
import { getIn } from 'utils/immutable'
import HasAccess from 'core/HasAccess'
import dimensions from '../../../dimensions'

const { Option } = Select

const Dimension = ({
  item, updateItem, permissions, option,
}) => {
  const getDefaultValue = () => {
    const dimensions = getIn(item, ['vizState', 'query', 'dimensions'])
    || getIn(item, ['vizState', 'query', 0, 'dimensions'])
    return dimensions.map((dimension) => dimension.split('.')[1])
  }

  const handleChange = (value) => {
    const alteredItem = Utils.Cube.updateQuery(item, alterQuery, value)
    updateItem(item.id, alteredItem)
  }

  const alterQuery = (query, value) => {
    let values = value
    if (!_.isArray(value)) values = [value]
    if (!value) values = []

    return { ...query, dimensions: values.map((v) => `${Utils.Cube.getSchema(item.vizState)}.${v}`) }
  }

  const type = Utils.Cube.getType(item)
  const filteredDimensions = dimensions.filter((dim) => !dim.intendedFor || dim.intendedFor === type)

  const isMulti = () => option.type !== 'single' && HasAccess.run(permissions, 'lpMultiDimensions')

  return (
    <Form.Item label="Dimension">
      <Select
        mode={isMulti() ? 'multiple' : null}
        defaultValue={isMulti() ? getDefaultValue() : getDefaultValue()[0]}
        onChange={handleChange}
        placeholder="Select dimension"
      >
        {!option.disable_empty && !isMulti() && <Option value="">Empty</Option>}
        {filteredDimensions.map((d) => <Option key={d.field} value={d.field}>{d.title}</Option>)}
      </Select>
    </Form.Item>
  )
}

export default Dimension
