import React, { useEffect, useState } from 'react'
import { Table } from 'antd'

const columns = [
  {
    dataIndex: 'name',
    align: 'left',
    key: 'name',
    title: 'Company',
  },
  {
    dataIndex: 'total',
    align: 'center',
    key: 'total',
    title: 'Total Users',
  },
  {
    dataIndex: 'logged_recently',
    align: 'center',
    key: 'logged_recently',
    title: 'Has logged in in last 30 days',
  },
  {
    dataIndex: 'run_case_recently',
    align: 'center',
    key: 'run_case_recently',
    title: 'Has ran a case in MBT in last 30 days',
  },
]

const UsersActivitiesTable = ({ height }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ELIXIR_API_URL}/lender_portal/users_activities`, {
      credentials: 'include',
    }).then((res) => res.json()).then((data) => {
      setData(data)
    })
  }, [])

  return (
    <>
      <div style={{ maxHeight: `${height - 4}px`, overflowY: 'auto' }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </div>
    </>
  )
}

export default UsersActivitiesTable
